import { Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { green, grey, orange, purple, red } from '@material-ui/core/colors';

import Layout from '../../components/Layout';
import { Link } from 'gatsby';
import PageTitle from '../../components/PageTitle';
import Point from '../../components/Point';
import { Post } from '../../components/Post';
import PostList from '../../components/PostList';
import SEO from '../../components/seo';
import Section from '../../components/Section';
import { SubscriptionForm } from '../../components/SubscriptionForm';
import YoutubePlayer from '../../components/YoutubePlayer';
import { graphql } from 'gatsby';
import image from './habit-tracker.png';

export default (props) => {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
    location,
  } = props;

  const [showDescription, setShowDescription] = useState(false);
  return (
    <>
      <Layout location={location} title={title} fullWidth>
        <SEO
          title={'Habit Tracker with Feelings'}
          keywords={[`habits`, `tracker`, `emojis`]}
        />
        <Section>
          <PageTitle>Habit Tracker, but with Feelings</PageTitle>
          <Typography gutterBottom>A fun alternative to checkboxes.</Typography>
          <img style={{ borderRadius: 4 }} src={image} />
        </Section>
        <Divider />
        <Section title={'Use it'}>
          <Typography
            variant={'h3'}
            style={{ fontWeight: 'bold' }}
            gutterBottom
          >
            Availability
          </Typography>
          <div
            style={{
              display: 'flex',
            }}
          >
            <a
              target={'_blank'}
              href={'https://habit-tracker-landing.netlify.app/'}
            >
              <Button color={'secondary'} variant={'contained'}>
                Visit PWA Web App
              </Button>
            </a>
          </div>
        </Section>

        <Section title={'JOIN'} color={'blue'}>
          <Typography
            variant={'h3'}
            gutterBottom
            style={{
              fontWeight: 'bold',
              lineHeight: 1,
            }}
          >
            Valuable Information, Regulary Delivered
          </Typography>
          <SubscriptionForm />
        </Section>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
